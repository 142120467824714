<template>
  <nuxt-link :to="url" class="mini-banner">
    <Image loading="lazy" :src="src" alt="Mini banner"/>

    <div class="price-p" v-if="price">
      <ProductPrice :unit="unit" :digits="0" :price="price"/>
    </div>
  </nuxt-link>
</template>

<script>
import Image from "~/components/atoms/Image.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";

export default {
  components: {ProductPrice, Image},
  props: {
    url: String,
    price: Number,
    src: String,
    unit: String
  },
  setup() {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.mini-banner {
  aspect-ratio: 1/0.172;
}

.price-p {
  position: absolute;
  bottom: 13px;
  left: 18px;
  filter: blur(0.3px);

  @include small-desktop {
    bottom: 2%;
  }

  @include medium {
    bottom: 2%;
  }

  &:deep(.row) {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.232px;

    @include small-desktop {
      font-size: 14px;
    }

    @include medium {
      font-size: 14px;
      bottom: 2%;
    }
  }
}
</style>
