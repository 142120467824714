<template>
  <Timer :date="date">
    <template v-slot:title>{{ title }}:</template>
    <template v-slot:default>
      <div class="square">
        <div class="discount">59%</div>
        <div class="sign">{{ $t("counter.up_to") }}</div>
      </div>
    </template>
  </Timer>
</template>

<script>
import Timer from "@/components/molecules/Timer.vue";

export default {
  components: {Timer},
  props: ['date', 'title'],
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

.square {
  background: $dark-black;
  color: white;
  width: 80px;
  max-width: 80px;
  height: 80px;
  border-radius: 5px;
  text-align: center;
  margin: -1px;
  margin-right: auto;

  @include mobile {
    width: 60px;
    max-width: 60px;
    height: 60px;
  }
}

.discount {
  font-size: 28px;
  font-weight: 600;
  margin: 8px auto auto;

  @include mobile {
    font-size: 20px;
  }
}

.sign {
  color: #fff;
  font-weight: 600;

  @include mobile {
    font-size: 10px;
  }
}
</style>
